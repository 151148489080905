import { Link } from 'react-router-dom';
import Card from '../common/Card';
import Badge from '../common/Badge';
import StarIcon from '../common/icons/StarIcon';
import { usePostHog } from 'posthog-js/react';
import Markdown from 'react-markdown';
import AnnotationDotsIcon from '../common/icons/AnnotationDotsIcon';
import ImageMountainIcon from '../common/icons/ImageMountainIcon';
import AudioWaveSymmetricIcon from '../common/icons/AudioWaveSymmetricIcon';
import { mapNumberFormatToTooltip } from '../../utils/models';

interface ModelCardProps {
  slug: string;
  name: string;
  type: string;
  description: string;
  pricePerToken: number;
  badgeText?: string;
  numberFormat?: string;
  image?: any;
}

const mapTypeToIcon: { [key: string]: any } = {
  llm: AnnotationDotsIcon,
  image: ImageMountainIcon,
  audio: AudioWaveSymmetricIcon,
};

const ModelCard = ({
  name,
  description,
  type,
  // pricePerToken,
  badgeText,
  numberFormat,
  image,
  slug,
}: ModelCardProps) => {
  const posthog = usePostHog();
  const Icon = mapTypeToIcon[type] || mapTypeToIcon.llm;

  return (
    <Link
      onClick={() => {
        posthog?.capture('Model Card Clicked', {
          slug,
          name,
          type,
        });
      }}
      to={`/models/${slug}`}
      className="w-full"
    >
      <Card className="transition h-full w-full flex text-theme-neutral-700 group hover:text-theme-primary-600">
        <div className="flex mr-4 relative">
          <img
            src={image?.filename}
            className="rounded-lg w-full object-cover"
            style={{ minWidth: 100, width: 100, maxWidth: 100, height: 100 }}
          />
          <div className="transition invisible w-[100px] h-[100px] absolute group-hover:visible flex bg-black bg-opacity-30 rounded-lg items-center justify-center">
            <Icon className="text-white" />
          </div>
        </div>
        <div className="flex flex-1 flex-col gap-3">
          <div className="flex items-center">
            <div className="xl:text-sm 2xl:text-base font-semibold flex-1">
              {name}
            </div>
            <div className="flex items-center gap-2">
              {numberFormat && (
                <Badge
                  id={`${slug}-number-format`}
                  text={numberFormat.toUpperCase()}
                  className="text-xs py-0 px-1.5 text-theme-neutral-600 border-theme-neutral-400 bg-transparent rounded"
                  tooltipText={
                    mapNumberFormatToTooltip[numberFormat.toLowerCase()]
                  }
                />
              )}
              {badgeText && (
                <Badge
                  logo={<StarIcon size={12} />}
                  logoClassName="mr-1"
                  text={badgeText}
                  className="text-xs py-0 px-1.5 rounded bg-transparent"
                />
              )}
            </div>
          </div>
          <div className="flex-1 text-sm xl:text-xs 2xl:text-sm text-theme-neutral-700 font-light [&_strong]:font-semibold">
            <Markdown>{description}</Markdown>
          </div>
          {/* <div className="text-theme-neutral-600 text-sm font-light">
          ${pricePerToken} / 1M tokens
        </div> */}
        </div>
      </Card>
    </Link>
  );
};

export default ModelCard;
