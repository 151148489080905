import { useParams } from 'react-router-dom';
import ModelChat from '../components/ModelChat';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../store';
import {
  changeTextParameter,
  fetchModels,
  getModel,
  getModelStatus,
  resetParameters,
} from '../slices/models';
import BorderDashedLine from '../components/common/icons/BorderDashedLine';
import HyperLink from '../components/common/HyperLink';
import StarLinkIcon from '../components/common/icons/StarLinkIcon';
import ModelApi from '../components/Models/ModelApi';
import ModelInputParameters from '../components/Models/ModelInputParameters';
import ModelImagesList from '../components/Models/ModelImagesList';
import { isMobile } from 'react-device-detect';
import { cancelAIResponse, resetChat } from '../slices/chat';
import ModelAudio from '../components/Models/ModelAudio';
import { ResponseStatus } from '../utils/types';

const mapModelTypeToComponent: { [key: string]: any } = {
  llm: ModelChat,
  image: ModelImagesList,
  audio: ModelAudio,
};

const ModelDetail = () => {
  const { modelId, mode } = useParams();
  const dispatch = useDispatch<AppDispatch>();
  const model = useSelector((state: RootState) => getModel(state, modelId));
  const modelStatus = useSelector(getModelStatus);
  const isLoading = modelStatus === ResponseStatus.Loading;
  const DemoComponent =
    mapModelTypeToComponent[model?.type || 'llm'] ||
    mapModelTypeToComponent.llm;

  useEffect(() => {
    if (modelId) {
      dispatch(fetchModels());
    }
    return () => {
      dispatch(resetParameters());
      dispatch(cancelAIResponse());
      dispatch(resetChat());
    };
  }, [modelId, dispatch]);

  useEffect(() => {
    if (model?.defaultMaxTokens) {
      dispatch(
        changeTextParameter({ id: 'maxTokens', value: model?.defaultMaxTokens })
      );
    }
    if (model?.defaultSystemPrompt) {
      dispatch(
        changeTextParameter({
          id: 'systemPrompt',
          value: model?.defaultSystemPrompt,
        })
      );
    }
    if (model?.defaultTemperature) {
      dispatch(
        changeTextParameter({
          id: 'temperature',
          value: model?.defaultTemperature,
        })
      );
    }
    if (model?.defaultTopP) {
      dispatch(
        changeTextParameter({
          id: 'topP',
          value: model?.defaultTopP,
        })
      );
    }
  }, [model, dispatch]);

  return (
    <div
      className="flex flex-col flex-1"
      style={isMobile ? {} : { height: 'calc(100% - 60px)' }}
    >
      <div className="flex flex-col-reverse lg:flex-row lg:gap-7 h-full overflow-y-auto px-7">
        <div className="flex-1 flex flex-col border border-theme-primary-100 bg-theme-primary-50 rounded-xl pt-5 lg:pt-6 pb-4 px-6 lg:px-6 mb-6 lg:overflow-hidden">
          <div className="flex mb-4 justify-between items-center">
            <HyperLink
              variant="outline"
              href={model?.hfLink || `https://huggingface.co/${model?.model}`}
              className="flex items-center cursor-pointer text-theme-neutral-700 hover:text-theme-primary-600"
              rel="noreferrer noopener"
              target="_blank"
            >
              <StarLinkIcon size={18} className="text-theme-primary-600" />
              <div className="ml-2 text-sm">{model?.name}</div>
            </HyperLink>
            <HyperLink variant="ghost" to={`/models/${modelId}/api`}>
              Try our API
            </HyperLink>
          </div>
          {/* <div className="text-theme-neutral-600 mb-4 text-sm [&_strong]:font-semibold">
            <Markdown>{model?.description}</Markdown>
          </div> */}
          <div className="flex text-left">
            <BorderDashedLine className="text-theme-neutral-400 mb-6 min-h-[1px]" />
          </div>
          {isLoading ? (
            <div />
          ) : mode === 'api' && model?.model ? (
            <ModelApi />
          ) : (
            <DemoComponent isVisionLanguage={model?.type === 'vlm'} />
          )}
        </div>
        <ModelInputParameters />
      </div>
    </div>
  );
};

export default ModelDetail;
