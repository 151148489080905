import { Outlet } from 'react-router-dom';
import HyperLink from '../components/common/HyperLink';
import { FiCodesandbox } from 'react-icons/fi';
import { discordLink, supportEmail } from '../utils/constants';

const Support = () => (
  <div className="flex flex-col w-full px-6 lg:px-16 gap-8 mb-10">
    <h2>Help Center</h2>
    <Outlet />
    <div className="w-full h-[1px] bg-theme-neutral-200" />
    <div className="py-12 flex px-6 lg:px-16 items-center justify-center gap-6 shadow-theme-02 rounded-lg border border-theme-primary-200">
      <FiCodesandbox size={24} className="hidden xs:block min-w-8" />
      <div className="flex flex-col items-center text-sm text-black">
        <div>
          If you didn't find what you're looking for, don't worry - we're still
          here for you!
        </div>
        <div className="flex flex-wrap">
          <HyperLink
            href={discordLink}
            rel="noreferrer noopener"
            target="_blank"
          >
            Chat with our friendly team on Discord
          </HyperLink>
          &nbsp; or send us an email at&nbsp;
          <HyperLink
            href={`mailto:${supportEmail}`}
            rel="noreferrer noopener"
            target="_blank"
          >
            {supportEmail}
          </HyperLink>
          .
        </div>
      </div>
    </div>
  </div>
);

export default Support;
