import hyperFetch from '../utils/fetch';
import hardcodedNodes from '../utils/hardcoded-node';

export const fetchMarketplace = async () => {
  const response = await hyperFetch(
    `${import.meta.env.VITE_BACKEND_HOST}/v1/marketplace`,
    { method: 'POST', body: JSON.stringify({ filters: {} }) }
  );
  return [...hardcodedNodes, ...response.instances];
};

export const fetchRented = async () => {
  const response = await hyperFetch(
    `${import.meta.env.VITE_BACKEND_HOST}/v1/marketplace/instances`
  );
  return response.instances;
};

export const fetchSupplied = async () => {
  const response = await hyperFetch(
    `${import.meta.env.VITE_BACKEND_HOST}/v1/marketplace/instances/supplied`
  );
  return response.nodes;
};

export const fetchSupplierInstructions = async () => {
  const fileUrl = `https://raw.githubusercontent.com/HyperbolicLabs/Hyper-dOS/${
    import.meta.env.VITE_ENV === 'dev' ? 'dev' : 'main'
  }/README.md`;

  const response = await fetch(fileUrl);
  const text = await response.text();
  return text;
};

export const terminateInstanceDb = (rentalId: string) =>
  hyperFetch(
    `${import.meta.env.VITE_BACKEND_HOST}/v1/marketplace/instances/terminate`,
    {
      method: 'POST',
      body: JSON.stringify({
        id: rentalId,
      }),
    }
  );

export interface DockerImage {
  name: string;
  tag: string;
  port: number;
}

export const rentInstanceDb = ({
  instanceId,
  clusterName,
  image,
}: {
  instanceId: string;
  clusterName: string;
  image?: DockerImage;
}) =>
  hyperFetch(
    `${import.meta.env.VITE_BACKEND_HOST}/v1/marketplace/instances/create`,
    {
      method: 'POST',
      body: JSON.stringify({
        cluster_name: clusterName,
        node_name: instanceId,
        image,
      }),
    }
  );
