import { FiX } from 'react-icons/fi';

import { ReactNode } from 'react';
import useLocalStorage from '../../hooks/useLocalStorage';
import twClassnames from '../../utils/classnames';

const Banner = ({
  storageKey,
  children,
  className,
}: {
  storageKey: string;
  children: ReactNode;
  className?: string;
}) => {
  const [bannerDisabled, setBannerDisabled] = useLocalStorage(storageKey);
  if (bannerDisabled) return null;
  const handleClose = () => {
    setBannerDisabled(true);
  };
  return (
    <div
      className={twClassnames(
        'flex items-center justify-center text-sm rounded-lg bg-theme-primary-100 py-3 gap-20 mb-8 px-8 mt-4',
        className
      )}
    >
      {children}
      <div>
        <FiX size={16} className="cursor-pointer" onClick={handleClose} />
      </div>
    </div>
  );
};

export default Banner;
