import { useState } from 'react';
import Input from '../Input';
import { ButtonGroup, ButtonGroupButton } from '../ButtonGroup';
import { formatCurrency } from '../../../utils/value';
import twClassnames from '../../../utils/classnames';

const AddFundsInputGroup = ({
  amount,
  disabled,
  onInputChanged,
  options = [25, 50, 100],
}: {
  amount: number;
  disabled: boolean;
  onInputChanged: ({ value }: { value: number | null }) => void;
  options: number[];
}) => {
  const [customSelected, setCustomSelected] = useState(false);

  const handleOptionSelected = async (value: number) => {
    setCustomSelected(false);
    onInputChanged({ value });
  };

  const handleCustomSelected = () => {
    if (!customSelected) {
      setCustomSelected(true);
      onInputChanged({ value: null });
    }
  };

  return (
    <ButtonGroup className="divide-theme-neutral-400">
      {options.map((o, i) => {
        const optionSelected = !customSelected && amount === o;
        return (
          <ButtonGroupButton
            key={`add_funds_input_${o}`}
            active={optionSelected}
            onClick={() => handleOptionSelected(o)}
            className={twClassnames(
              // override defaults
              'text-black border-r first:border-theme-neutral-400 first:hover:border-theme-neutral-400 hover:bg-theme-neutral-300',
              // custom
              'font-medium max-w-24',
              {
                'text-white bg-theme-primary-600 hover:bg-theme-primary-600 !border-theme-primary-600':
                  optionSelected,
              }
            )}
          >
            {formatCurrency(o, {
              maximumFractionDigits: 0,
            })}
          </ButtonGroupButton>
        );
      })}
      <div
        key="add_funds_input_custom"
        className={twClassnames(
          'flex flex-1 items-center justify-center px-4 transition outline-none hover:bg-theme-neutral-300 text-sm text-black whitespace-nowrap border border-l-0 border-theme-neutral-400 rounded-lg first:rounded-l-lg last:rounded-r-lg rounded-none',
          {
            'text-white bg-theme-primary-600 hover:bg-theme-primary-600 border-theme-primary-600 hover:border-theme-primary-600 border-0':
              customSelected,
          }
        )}
      >
        <span>$</span>
        <Input
          className={twClassnames(
            // override defaults
            'py-0 px-0 border-none !outline-none bg-transparent disabled:bg-transparent placeholder:text-theme-neutral-500',
            // custom
            'text-black text-center min-w-12',
            {
              'text-white': customSelected,
            }
          )}
          type="text"
          pattern="[0-9.]+"
          disabled={disabled}
          value={customSelected ? amount || '' : ''}
          placeholder={customSelected ? '' : 'other'}
          onChangeText={(t) => {
            const re = /[0-9.]+/;
            const match = re.exec(t);
            if (t === '' || match) {
              const value = Number(match?.[0]) || null;
              onInputChanged({ value });
            }
          }}
          onFocus={handleCustomSelected}
        />
      </div>
    </ButtonGroup>
  );
};

export default AddFundsInputGroup;
