import { useNavigate } from 'react-router-dom';

import Button from '../common/Button';
import ModalClose from '../common/ModalClose';
import { useState } from 'react';
import { InstanceStatus } from '../../utils/types';
import Card from '../common/Card';
import Input from '../common/Input';
import { formatCurrency } from '../../utils/value';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { getUserInfo } from '../../slices/auth';
import PublicKeyDropzone from '../common/PublicKeyDropzone';
import { displayModelName, dockerImages } from '../../utils/instances';
import LockIcon from '../common/icons/LockIcon';
import NumberStepper from '../common/NumberStepper';
import { AppDispatch, RootState } from '../../store';
import {
  getFormattedMarketplaceInstance,
  rentInstance,
} from '../../slices/instances';
import { usePostHog } from 'posthog-js/react';
import HyperLink from '../common/HyperLink';
import Dropdown, {
  DropdownButton,
  DropdownMenu,
  DropdownMenuItem,
  DropdownOverlay,
} from '../common/Dropdown';

const ConfirmRentModal = ({
  onClose,
  instanceId,
}: {
  onClose: () => void;
  instanceId: string;
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const posthog = usePostHog();
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const userInfo = useSelector(getUserInfo);
  const [errorMessage, setErrorMessage] = useState('');
  const instance = useSelector((state: RootState) =>
    getFormattedMarketplaceInstance(state, instanceId)
  );

  const [gpuCount, setGPUCount] = useState(instance?.gpuCount || 1);
  const selectedImage = dockerImages[selectedImageIndex] || dockerImages[0];

  if (!instance) {
    onClose();
    return null;
  }

  const { gpu, gpuCount: defaultGPUCount, totalPrice } = instance;
  const isLoading = instance.status === InstanceStatus.starting;

  const onSuccess = () => {
    onClose();
    posthog?.capture('Rent Instance Confirmed', {
      instanceId,
      clusterName: instance?.clusterName,
    });
    navigate('/compute/instances');
    toast.success('Created instance successfully!');
  };

  const onError = (error: any) => {
    setErrorMessage(error.message || 'Something went wrong');
    posthog?.capture('Rent Instance Failed', {
      instanceId,
      clusterName: instance?.clusterName,
    });
    toast.error('Error creating instance');
  };

  const handleConfirm = async () => {
    setErrorMessage('');
    const { packageId } = selectedImage;

    const newInstance = {
      instanceId: instance?.id || '',
      clusterName: instance?.clusterName || '',
      ...(packageId && {
        image: {
          name: `ghcr.io/hyperboliclabs/hyper-dos/${packageId}`,
          tag: 'dev',
          port: 80,
        },
      }),
    };
    try {
      await dispatch(rentInstance(newInstance)).unwrap();
      onSuccess();
    } catch (error: any) {
      onError(error);
    }
  };

  const handleClose = () => {
    onClose();
  };

  const addDisabled = gpuCount >= defaultGPUCount;
  const subtractDisabled = gpuCount <= 1;

  const addGpu = () => {
    if (gpuCount < defaultGPUCount) {
      setGPUCount(gpuCount + 1);
    }
  };

  const subtractGpu = () => {
    setGPUCount(gpuCount - 1);
  };

  const buttonsDisabled = !!isLoading;

  return (
    <div className="p-4">
      <ModalClose onClose={handleClose} />
      <div className="text-black text-center text-lg font-semibold mb-8">
        Rent Confirmation
      </div>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-3">
          <div className="text-black font-semibold text-sm">GPU Type:</div>
          <div>
            <Input
              type="text"
              value={displayModelName(gpu?.model)}
              disabled
              endIcon={<LockIcon size={20} />}
              endIconClassName="mt-2 right-4 text-theme-neutral-400"
            />
          </div>
        </div>
        <div className="flex flex-col gap-3 items-start">
          <div className="text-black font-semibold text-sm">GPU Count*:</div>
          <NumberStepper
            disabled
            addDisabled={addDisabled}
            subtractDisabled={subtractDisabled}
            add={addGpu}
            subtract={subtractGpu}
            value={gpuCount}
          />
        </div>
        <div className="flex flex-col gap-3">
          <div className="text-black font-semibold text-sm">Template*:</div>
          <div>
            <Dropdown>
              <DropdownButton textClassName="flex items-center gap-2">
                {selectedImage?.title}
                {selectedImageIndex === 0 && (
                  <div className="text-theme-primary-600">(default)</div>
                )}
              </DropdownButton>
              <DropdownOverlay>
                <DropdownMenu>
                  {dockerImages.map((image, i) => (
                    <DropdownMenuItem
                      key={image?.title}
                      active={selectedImage?.title === image?.title}
                      onClick={() => setSelectedImageIndex(i)}
                      childClassName="flex items-center gap-2"
                    >
                      {image?.title}
                      {i === 0 && (
                        <div className="text-theme-primary-600">(default)</div>
                      )}
                    </DropdownMenuItem>
                  ))}
                </DropdownMenu>
              </DropdownOverlay>
            </Dropdown>
          </div>
        </div>
        <div className="flex flex-col gap-3">
          <div className="text-black font-semibold text-sm">Pricing*:</div>
          <div className="flex gap-4 overflow-auto hide-scrollbar">
            {/* {Object.keys(pricingTypes).map((p) => {
            const config = pricingTypes[p];
            return (
              <Card className="min-w-80 cursor-not-allowed select-none">
                <div className="font-2xl font-semibold">{config.label}</div>
                <div className="text-2xl text-theme-primary-500 font-bold my-3">
                  {formatCurrency(config.amount)} / hr
                </div>
                <div>{config.description}</div>
              </Card>
            );
          })} */}
            <Card
              noHover
              className="max-w-60 gap-2 flex flex-col text-sm bg-white"
            >
              <div className="font-semibold text-theme-neutral-700">
                On-Demand
              </div>
              <div className="text-theme-primary-600 font-semibold">
                {formatCurrency(totalPrice)} / hr
              </div>
              <div className="text-xs">
                Pay as you go, with costs based on actual usage time
              </div>
            </Card>
          </div>
        </div>
        {!userInfo?.public_key && (
          <div className="flex flex-col gap-3">
            <div className="text-black font-semibold text-sm">
              SSH Public Key*:
            </div>
            <div className="text-xs">
              Your public key is required to access instances. Check&nbsp;
              <HyperLink
                to="/support/marketplace"
                className="text-theme-neutral-600 underline hover:text-theme-neutral-700 text-xs"
                state={{ initialQuestion: 3 }}
                onClick={onClose}
              >
                support
              </HyperLink>
              &nbsp; if you have questions.
            </div>
            <PublicKeyDropzone
              containerClassName="w-full"
              className="w-full lg:w-full md:w-full h-32"
            />
          </div>
        )}
        <div className="flex flex-col gap-3">
          <div className="text-black font-semibold text-sm">Summary:</div>
          <Card
            noHover
            className="w-full py-4 text-sm flex flex-col gap-3 border-0"
          >
            <div className="flex justify-between items-center">
              <div className="font-semibold text-black">
                {displayModelName(gpu?.model)} * {gpuCount}
              </div>
              <div>
                <Button
                  variant="link"
                  className="text-xs"
                  onClick={() => {
                    setIsExpanded(!isExpanded);
                  }}
                >
                  {isExpanded ? 'Hide' : 'Show'} Details
                </Button>
              </div>
            </div>
            <div className="flex justify-between text-xs text-theme-neutral-600">
              <div>Machine Cost</div>
              <div>{formatCurrency(totalPrice)} / hr</div>
            </div>
            <div className="flex justify-between text-xs text-theme-neutral-600">
              <div>Running Disk Cost</div>
              <div>$0.000 / hr</div>
            </div>
            {isExpanded && (
              <>
                <div className="flex justify-between text-xs text-black">
                  <div>GPU RAM: {instance.gpuRamGB} GB</div>
                  <div>{instance.storageCapacity} Storage</div>
                </div>
                <div className="flex justify-between text-xs text-black">
                  <div>CPU RAM: {instance.ramCapacity}</div>
                  <div>CPU Cores: {instance.cpu?.cores}</div>
                </div>
              </>
            )}
          </Card>
        </div>
        {errorMessage && (
          <div className="text-theme-danger-500 text-sm">{errorMessage}</div>
        )}
        <div className="flex justify-between gap-x-8 mt-3">
          <Button className="flex-1" onClick={onClose} variant="outline">
            Cancel
          </Button>
          <Button
            onClick={handleConfirm}
            className="flex-1"
            disabled={
              buttonsDisabled || !userInfo?.public_key || instance.reserved
            }
            isLoading={isLoading}
          >
            {instance.reserved ? 'Reserved' : 'Confirm'}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmRentModal;
