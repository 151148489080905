import { DateRange } from 'react-day-picker';
import { priceOptions, quantityOptions } from '../../hooks/useInstanceFilters';
import DurationDropdown from './DurationDropdown';
import FilterDropdown from './FilterDropdown';
import { Option } from '../../utils/types';
import twClassnames from '../../utils/classnames';
import posthog from 'posthog-js';

interface InstanceFiltersProps {
  gpuFilter: string;
  gpuOptions: Option[];
  quantityFilter: string;
  storageFilter: string;
  filteredStorageOptions: Option[];
  locationFilter: string;
  locationOptions: Option[];
  priceFilter: 'any' | 'asc' | 'desc';
  daysFilter: DateRange | undefined;
  handleFilterChange: ({ id, value }: { id: string; value: any }) => void;
  theme: 'marketplace' | 'rented' | 'history';
}

interface FilterClassNames {
  container: string;
  button: string;
}

const filterClassNames: { [key: string]: FilterClassNames } = {
  marketplace: {
    container: 'bg-theme-primary-50',
    button: '',
  },
  rented: {
    container: '',
    button: 'bg-white hover:bg-theme-neutral-200 hover:text-theme-neutral-600',
  },
};

const InstanceFilters = ({
  quantityFilter,
  handleFilterChange,
  gpuOptions,
  gpuFilter,
  filteredStorageOptions,
  storageFilter,
  locationOptions,
  locationFilter,
  daysFilter,
  priceFilter,
  theme = 'marketplace',
}: InstanceFiltersProps) => {
  const classNames = filterClassNames[theme];
  const handleFilterDropdownChange = (filterId: string) => (value: any) => {
    posthog?.capture('Instance Filter Changed', {
      filterId,
      filterSelected: value,
      location: theme,
    });
    handleFilterChange({ id: filterId, value });
  };
  return (
    <div className={twClassnames('flex rounded-lg', classNames.container)}>
      <div className="hidden sm:flex">
        <FilterDropdown
          options={quantityOptions}
          filter={quantityFilter}
          onChange={handleFilterDropdownChange('quantityFilter')}
          buttonClassName={twClassnames('w-32', classNames.button)}
          overlayClassName="w-32"
          placeholder="Quantity"
        />
      </div>
      <div className="flex-1">
        <FilterDropdown
          dropdownClassName="inline-block"
          overlayClassName="w-48"
          options={gpuOptions}
          filter={gpuFilter}
          buttonClassName={classNames.button}
          onChange={handleFilterDropdownChange('gpuFilter')}
          placeholder="GPU Type"
        />
      </div>
      <div className="hidden sm:flex flex-1">
        <FilterDropdown
          dropdownClassName="inline-block"
          overlayClassName="w-44"
          options={filteredStorageOptions}
          filter={storageFilter}
          buttonClassName={classNames.button}
          onChange={handleFilterDropdownChange('storageFilter')}
          placeholder="Storage"
        />
      </div>
      <div className="hidden lg:flex flex-1">
        <FilterDropdown
          dropdownClassName="inline-block"
          placeholder="Location"
          options={locationOptions}
          filter={locationFilter}
          buttonClassName={classNames.button}
          onChange={handleFilterDropdownChange('locationFilter')}
          overlayClassName="w-44"
        />
      </div>
      <div className="hidden lg:flex flex-1">
        <DurationDropdown
          range={daysFilter}
          buttonClassName={classNames.button}
          onChangeRange={handleFilterDropdownChange('daysFilter')}
        />
      </div>
      <div className="flex-1">
        <FilterDropdown
          dropdownClassName="inline-block"
          placeholder="Price"
          options={priceOptions}
          filter={priceFilter}
          buttonClassName={classNames.button}
          onChange={handleFilterDropdownChange('priceFilter')}
          defaultLabel="Show all"
          overlayClassName="w-48"
        />
      </div>
      <div className="w-6" />
    </div>
  );
};

export default InstanceFilters;
