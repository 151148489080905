import { Message } from '../slices/chat';
import { VLMContent } from './models';

interface MixedContentMessage {
  role: string;
  content: string | VLMContent[];
  image?: string;
}

export const formatMessagesWithHistory = (
  content: string,
  chatHistory: Message[],
  systemPrompt?: string,
  image?: string,
  vlmContent?: VLMContent[]
) => {
  const messages: MixedContentMessage[] = [];
  if (systemPrompt) {
    messages.unshift({
      role: 'system',
      content: systemPrompt,
    });
  }
  const formattedHistory: MixedContentMessage[] = chatHistory.map((msg) => ({
    role: msg.from === 'ai' ? 'assistant' : 'user',
    content: msg.text,
  }));
  const messagesWithHistory: MixedContentMessage[] = messages
    .concat(formattedHistory)
    .concat([
      {
        role: 'user',
        content: vlmContent || content,
        ...(image && { image, image_process_mode: 'Default' }),
      },
    ]);
  return messagesWithHistory;
};
