import { useDispatch, useSelector } from 'react-redux';
import { convertKeysToSnake, VLMContent } from '../utils/models';
import { getTextInputParameters } from '../slices/models';
import { useRef } from 'react';
import { decodeUtf8 } from '../utils/text-encoding';
import { auth } from '../utils/firebase';
import omit from 'lodash/omit';
import { Model } from '../utils/types';
import { ERROR_CODES, HTTP_STATUS } from '../utils/constants';
import {
  Message,
  addAIResponse,
  finalizeAIMessage,
  getAIResponseError,
  getAIResponseLoading,
  setAIReader,
  setAIResponseError,
  setAIResponseLoading,
} from '../slices/chat';
import { AppDispatch } from '../store';
import { formatMessagesWithHistory } from '../utils/chat';

const useAIResponse = ({
  model,
  chatMessages,
  modelName = 'NousResearch/Nous-Hermes-2-Mixtral-8x7B-DPO',
}: {
  model?: Model;
  chatMessages: Message[];
  modelName?: string;
}) => {
  const dispatch = useDispatch<AppDispatch>();
  const inputParameters = useSelector(getTextInputParameters);
  const unfinishedData = useRef<string>('');
  const isLoading = useSelector(getAIResponseLoading);
  const error = useSelector(getAIResponseError);
  const modelUrl =
    model?.url || 'http://142.202.69.115:8000/v1/chat/completions';

  const extractErrorMessage = (data: any) => {
    if (data.object == 'error') {
      const { code, message } = data;
      const errorMessage =
        ERROR_CODES[code]?.message || `${message}${code ? ` [${code}]` : ''}`;
      return errorMessage;
    }
  };

  const handleAIResponse = async (
    text: string,
    image?: string,
    message?: { id: string }
  ) => {
    const accessToken = await auth.currentUser?.getIdToken();
    dispatch(setAIResponseLoading(true));
    const params = omit(inputParameters.values, ['systemPrompt']);
    const isVLM = model?.type === 'vlm';
    const content: string = text;

    const vlmContent: VLMContent[] | undefined = isVLM
      ? [{ type: 'text', text }]
      : undefined;
    if (vlmContent && image) {
      vlmContent.push({
        type: 'image_url',
        image_url: {
          url: `data:image/jpeg;base64,${image}`,
        },
      });
    }

    const messagesWithHistory = formatMessagesWithHistory(
      content,
      chatMessages,
      inputParameters.values.systemPrompt,
      isVLM ? undefined : image,
      vlmContent
    );
    const payload =
      model?.subType === 'base'
        ? { prompt: text }
        : { messages: messagesWithHistory };
    const response = await fetch(modelUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `bearer ${accessToken}`,
      },
      body: JSON.stringify({
        model: modelName,
        stream: true,
        ...payload,
        ...convertKeysToSnake(params),
      }),
    });

    switch (response.status) {
      case HTTP_STATUS.TOO_MANY_REQUESTS:
        dispatch(
          setAIResponseError({
            error: 'Too many requests, please try again later',
            messageId: message?.id,
          })
        );
        break;
      case HTTP_STATUS.PAYMENT_REQUIRED:
        dispatch(
          setAIResponseError({
            error: 'Insufficent funds, please add more now',
            messageId: message?.id,
          })
        );
        break;
      default:
        break;
    }

    const reader = response.body?.getReader();
    dispatch(setAIReader(reader));

    // eslint-disable-next-line no-constant-condition
    while (true) {
      const reading = await reader?.read();
      const { done, value } = reading || {};
      if (done) {
        dispatch(finalizeAIMessage());
        break;
      }
      let decodedValue = decodeUtf8(value);

      if (unfinishedData.current) {
        decodedValue = unfinishedData.current + decodedValue;
        unfinishedData.current = '';
      }
      const dataArr = decodedValue
        .split('\n\n')
        .map((d) => {
          const jsonString = d.replace('data: ', '').trim();
          try {
            const jsonData = JSON.parse(jsonString);
            // todo: if we fail to JSON.parse for a legitimate reason,
            // we might not be handling that
            const errorMessage = extractErrorMessage(jsonData);
            if (errorMessage) {
              return {
                type: 'error',
                message: errorMessage,
              };
            }
            const messageText =
              model?.subType === 'base'
                ? jsonData.choices[0].text
                : jsonData.choices[0].delta.content;
            return { type: 'message', message: messageText };
          } catch (e) {
            unfinishedData.current = jsonString;
            return null;
          }
        })
        .filter((d) => !!d);

      const error = dataArr.find((m) => m?.type === 'error');
      if (error) {
        // todo: if one message errors, then all messages error
        // is this right to do?
        dispatch(
          setAIResponseError({
            error: error.message,
            messageId: message?.id,
          })
        );
        return;
      }
      dispatch(addAIResponse(dataArr.map((m) => m?.message)));
    }
  };

  return { handleAIResponse, isLoading, error };
};

export default useAIResponse;
