import noop from 'lodash/noop';
import twClassnames from '../utils/classnames';
import { ReactElement } from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';

const NavLinkItem = ({
  to,
  logo,
  title,
  condensed = false,
  onClick = noop,
  hasUpdates = false,
}: {
  to: string;
  logo?: ReactElement;
  title: string;
  condensed?: boolean;
  onClick?: () => void;
  hasUpdates?: boolean;
}) => {
  const id = `navbar-${to}`;
  return (
    <NavLink
      to={to}
      className={({ isActive, isPending }) =>
        twClassnames(
          `flex flex-nowrap items-center no-underline text-theme-neutral-700 hover:bg-theme-primary-100 hover:text-theme-primary-600 py-3 px-3.5 rounded-lg my-0.5 transition-all`,
          {
            'bg-theme-primary-600 hover:bg-theme-primary-600 hover:text-white text-white':
              isActive || isPending,
          },
          {
            'px-3': condensed,
          }
        )
      }
      data-tooltip-id={`${id}-tooltip`}
      onClick={onClick}
    >
      {logo && (
        <div className={twClassnames('relative', { 'mr-4': !condensed })}>
          <div>{logo}</div>
          {hasUpdates && (
            <div className="h-2 w-2 bg-theme-primary-600 ring-2 ring-theme-primary-50 rounded-full top-0 right-0 -mt-1 -mr-1 absolute" />
          )}
        </div>
      )}
      {!condensed && (
        <div className="text-sm font-medium text-nowrap">{title}</div>
      )}
      {condensed && (
        <Tooltip id={`${id}-tooltip`} className="z-10" place="right">
          {title}
        </Tooltip>
      )}
    </NavLink>
  );
};

export default NavLinkItem;
