import { ModalName } from '../components/modals';
import { discordLink, supportEmail, twitterLink } from './constants';

export const supportSections = [
  {
    id: 'start',
    title: 'Getting Started',
    description:
      'Here is a list of the most common questions that people have asked about our app.',
    questions: [
      {
        question: 'Where can I top up my funds?',
        answer: `The easiest way is to go to the top bar and click 'Deposit'!

You can also go to the [Billing page](/billing) to purchase more credits.`,
      },
      // {
      //   question: 'Where can I see my billing history?',
      //   answer: `Let's take you to Billing > Billing History`,
      // },
      {
        question: 'What is the inference playground?',
        answer:
          'Inference playground allows you to interact with all the AI models we have so you can demo the behavior and test the performance of the model before calling the API.',
      },
      {
        question: 'What is the GPU marketplace?',
        answer:
          'You can rent different kinds of GPUs from either data centers or individuals with unbeatable pricing on our marketplace!\n\nStart [renting now](/compute).',
      },
    ],
  },
  {
    id: 'inference',
    title: 'Inference',
    description:
      'Here is a list of the most common questions that people have asked about our AI inference service.',
    questions: [
      {
        question: 'How can I access my API keys?',
        answer: `It's simple! You can see it in [Settings](/settings) > [API Key](/settings#api-key).`,
      },
      {
        question: 'Is the inference service free?',
        answer:
          'We provide select models for free as we scale our services.\n\nTo view which models require payment, please visit our [pricing page](https://docs.hyperbolic.xyz/docs/hyperbolic-ai-inference-pricing)',
      },
      {
        question: 'Can you help host my custom models?',
        answer: `Yes, we provide dedicated hosting services along with a team of experts to meet all your needs.\n\nPlease kindly email us at [${supportEmail}](mailto:${supportEmail}) for more information, or join our [discord server](${discordLink}) to chat directly with our friendly team.`,
      },
      {
        question: 'Do you have fine-tune service?',
        answer: `Yes, we offer fine-tuning services to ensure optimal performance and customization of AI models.\n\nInterested? Our team of experts is here to help! Contact us at [${supportEmail}](mailto:${supportEmail}), or join our [discord server](${discordLink}) to chat directly with our friendly team.`,
      },
      {
        question: 'Do you offer video generation models?',
        answer: `Currently, we do not offer video generation models as there aren't any open-source models that meet our quality standards.\n\nHowever, we are committed to being the first to host a state-of-the-art open-source video generation model as soon as one becomes available.`,
      },
      {
        question: 'What is BF16/FP8?',
        answer: `BF16 (16-bit Brain Floating Point):\n\nBF16 retains more accuracy, making it suitable for tasks where precision is critical like medical diagnostics or scientific research. With BF16, you get reliable results without compromising speed, though it comes at a slightly higher cost.\n\nFP8 (8-bit Floating Point):\n\nFP8 is all about efficiency. It's fast, lean, and perfect for applications where speed matters more than precision. Think real-time tasks like recommendations or large-scale image processing. FP8 helps you scale at a lower cost, making it ideal for high-throughput needs.`,
      },
    ],
  },
  {
    id: 'marketplace',
    title: 'GPU Rental',
    description:
      'Here is a list of the most common questions that people have asked about our GPU Rental service.',
    questions: [
      {
        question: 'Why do I need to sign up for the waitlist?',
        answer: `Our product is currently in alpha and was recently released. Our plan is to gradually open the service to the public as it becomes more mature and user-friendly.

The good news is that we are offering a limited-time deal for our selected Alpha Testers: $50 in free credits to rent GPUs!

[Sign up for the waitlist now](~${ModalName.GPURequestModal}).`,
        hideAlpha: true,
      },
      {
        question: 'When can I use your product?',
        answer: `Selected Alpha Testers can start accessing our GPU marketplace starting August 6th.
        
The general public will be able to access the product after it is fully tested and ready to go public. Our product beta is expected to start two months after the alpha release.

[Follow us on X](${twitterLink}) or [sign up for our newsletter](https://share.hsforms.com/1IVbCqX4dQ9OTVYc54_g_Ngqsf7s) to stay updated!`,
      },
      {
        question: 'What is an SSH key?',
        answer: `An SSH key is a secure, encrypted password used to connect to a rented GPU server remotely. It ensures only authorized users can access and control the GPU for their tasks.
        
An SSH key pair consists of two keys: a public key and a private key. The public key is shared and uploaded to our platform, while the private key is kept secure on your computer. Together, they enable secure access to the rented GPU.`,
      },
      {
        question: 'How can I generate my SSH keys?',
        answer: `To generate your SSH keys for accessing a rented GPU on our platform:

1. **Open a terminal** on your computer.
2. Type \`ssh-keygen\` and press Enter.
3. **Follow the prompts** to create your SSH keys.
4. **Upload the public key** to your account on [Settings](/settings) > [Public key](/settings#public-key).

This will allow you secure, remote access to your rented GPU.

If you already have a public key, you may use this one and not need to generate a new one.`,
      },
      {
        question: 'How can I reset my SSH keys?',
        answer: `To re-generate your an SSH key, you may follow the commands in the above question.
        
Once you have generated a new SSH key, upload the public key to your account on [Settings](/settings) > [Public key](/settings#public-key).`,
      },
      {
        question: `Why can't I connect to my instance?`,
        answer: `If you're having trouble connecting to your instance, please contact us at [${supportEmail}](mailto:${supportEmail}) or reach out in [discord](${discordLink}).`,
      },
      {
        question: `What if I can't find any GPUs I want?`,
        answer: `You can fill out the “[Notify Me When Available](~${ModalName.NotifyMe})” form for your desired GPU type. We will notify you as soon as it becomes available for rent.`,
      },
    ],
  },
];
