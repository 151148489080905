import toast, { ToastBar, Toaster } from 'react-hot-toast';
import ModalPortal from './ModalPortal';
import Header from './Header';
import SideNavBar from './SideNavBar';
import twClassnames from '../utils/classnames';
import { Outlet, useLocation } from 'react-router-dom';
import backgroundImage from '../assets/login-background.png';
import { useSelector } from 'react-redux';
import { getIsAuthenticated } from '../slices/auth';
import useVerifyUser from '../hooks/useOnboardUser';
import { FiCheckCircle, FiX } from 'react-icons/fi';
import AlertTriangle from './common/AlertTriangle';

const Base = () => {
  const isAuthenticated = useSelector(getIsAuthenticated);
  const location = useLocation();
  const showBackgroundImage = !isAuthenticated && location.pathname === '/';

  useVerifyUser();

  return (
    <>
      <div className="flex h-screen">
        <SideNavBar />
        <main
          className={twClassnames(
            'flex flex-col flex-1 md:h-screen overflow-y-auto',
            { 'bg-no-repeat bg-cover bg-left-bottom': showBackgroundImage }
          )}
          {...(showBackgroundImage && {
            style: { backgroundImage: `url(${backgroundImage})` },
          })}
        >
          <Header className={showBackgroundImage ? 'bg-transparent' : ''} />
          <Outlet />
        </main>
      </div>
      <ModalPortal />
      <Toaster
        toastOptions={{
          loading: {
            className: '!text-white !text-sm !bg-theme-primary-600 !px-4 !py-1 !shadow-none',
          },
          success: {
            className:
              '!text-theme-success-700 !text-sm !bg-theme-success-50 !px-4 !py-1 !shadow-none',
            icon: <FiCheckCircle />,
          },
          error: {
            className:
              '!text-theme-danger-700 !text-sm !bg-theme-danger-50 !px-4 !py-1 !shadow-none',
            icon: <AlertTriangle size={16} />,
          },
          position: 'top-right',
        }}
        containerClassName="mt-12"
      >
        {(t) => (
          <ToastBar toast={t}>
            {({ icon, message }) => (
              <>
                {icon}
                {message}
                {t.type !== 'loading' && (
                  <FiX
                    size={20}
                    className="text-theme-neutral-600 cursor-pointer"
                    onClick={() => toast.dismiss(t.id)}
                  />
                )}
              </>
            )}
          </ToastBar>
        )}
      </Toaster>
    </>
  );
};

export default Base;
