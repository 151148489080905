import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import {
  ResponseStatus,
  InstanceRental,
  InstanceResponse,
  InstanceStatus,
  Instance,
} from '../utils/types';
import {
  fetchMarketplace,
  fetchRented,
  fetchSupplied,
  fetchSupplierInstructions,
  rentInstanceDb,
  terminateInstanceDb,
} from '../services/marketplace';
import { RootState } from '../store';
import { formatInstance } from '../utils/instances';

interface InstanceSlice {
  marketplaceInstances: InstanceResponse[];
  marketplaceStatus: ResponseStatus;
  marketplaceError: string;
  marketplaceInitialFetch: boolean;
  rentedInstances: InstanceRental[];
  rentedInstancesStatus: ResponseStatus;
  rentedInstancesError: string;
  rentedInstancesInitialFetch: boolean;
  suppliedInstances: InstanceResponse[];
  supplierInstructions: string | undefined;
  status: ResponseStatus;
  error: string;
}

const initialState: InstanceSlice = {
  marketplaceInstances: [],
  marketplaceStatus: ResponseStatus.Unfetched,
  marketplaceError: '',
  marketplaceInitialFetch: false,
  rentedInstances: [],
  rentedInstancesStatus: ResponseStatus.Unfetched,
  rentedInstancesError: '',
  rentedInstancesInitialFetch: false,
  suppliedInstances: [],
  supplierInstructions: undefined,
  status: ResponseStatus.Unfetched,
  error: '',
};

const fetchMarketplaceInstancesConstant = 'fetchMarketplaceInstances';
const rentMarketplaceInstancesConstant = 'rentMarketplaceInstances';
const fetchRentedInstancesConstant = 'fetchRentedInstances';
const fetchSuppliedInstancesConstant = 'fetchSuppliedInstances';
const fetchSupplierInstructionsConstant = 'fetchSupplierInstructions';
const terminateInstanceConstant = 'terminateInstance';
const rentInstanceConstant = 'rentInstanceConstant';

const buildOptimisticInstanceRental = (instance: InstanceResponse) => {
  const optimisticInstanceRental: InstanceRental = {
    id: 'optimistic',
    start: new Date().toISOString(),
    end: null,
    instance: formatInstance(instance),
    sshCommand: '',
  };
  return optimisticInstanceRental;
};

const instancesSlice = createSlice({
  name: 'instances',
  initialState,
  reducers: {
    // fetch marketplace instances
    [`${fetchMarketplaceInstancesConstant}/pending`]: (state) => {
      state.marketplaceStatus = ResponseStatus.Loading;
    },
    [`${fetchMarketplaceInstancesConstant}/fulfilled`]: (state, action) => {
      state.marketplaceStatus = ResponseStatus.Success;
      state.marketplaceInitialFetch = true;
      const existingInstances = state.marketplaceInstances;
      state.marketplaceInstances = action.payload.map((node: Instance) => {
        const existingInstance = existingInstances.find(
          (instance) => instance.id === node.id
        );
        if (existingInstance?.status === InstanceStatus.starting) {
          return existingInstance;
        }
        return node;
      });
    },
    [`${fetchMarketplaceInstancesConstant}/rejected`]: (state, action) => {
      state.marketplaceStatus = ResponseStatus.Failure;
      state.marketplaceError = action.payload;
    },
    // fetch rented instances
    [`${fetchRentedInstancesConstant}/pending`]: (state) => {
      state.rentedInstancesStatus = ResponseStatus.Loading;
    },
    [`${fetchRentedInstancesConstant}/fulfilled`]: (state, action) => {
      state.rentedInstancesStatus = ResponseStatus.Success;
      state.rentedInstancesInitialFetch = true;
      const existingRentals = state.rentedInstances;
      state.rentedInstances = action.payload.map((rental: InstanceRental) => {
        const existingRental = existingRentals.find(
          (eRental) => eRental.instance.id === rental.instance.id
        );
        const existingStoppage =
          existingRental?.instance.status === InstanceStatus.stopping ||
          existingRental?.end;
        if (existingStoppage) {
          return existingRental;
        }
        return rental;
      });
    },
    [`${fetchRentedInstancesConstant}/rejected`]: (state, action) => {
      state.rentedInstancesStatus = ResponseStatus.Failure;
      state.rentedInstancesError = action.payload;
    },
    // fetch supplied instances
    [`${fetchSuppliedInstancesConstant}/pending`]: (state) => {
      state.status = ResponseStatus.Loading;
    },
    [`${fetchSuppliedInstancesConstant}/fulfilled`]: (state, action) => {
      state.status = ResponseStatus.Success;
      state.suppliedInstances = action.payload;
    },
    [`${fetchSuppliedInstancesConstant}/rejected`]: (state, action) => {
      state.status = ResponseStatus.Failure;
      state.error = action.payload;
    },
    // rent marketplace instances (does this even have to update anything?)
    [`${rentMarketplaceInstancesConstant}/pending`]: (state) => {
      state.status = ResponseStatus.Loading;
    },
    [`${rentMarketplaceInstancesConstant}/fulfilled`]: (state) => {
      state.status = ResponseStatus.Success;
    },
    [`${rentMarketplaceInstancesConstant}/rejected`]: (state, action) => {
      state.status = ResponseStatus.Failure;
      state.error = action.payload;
    },
    // fetch supplier instructions
    [`${fetchSupplierInstructionsConstant}/pending`]: () => {},
    [`${fetchSupplierInstructionsConstant}/fulfilled`]: (state, action) => {
      state.supplierInstructions = action.payload;
    },
    [`${fetchSupplierInstructionsConstant}/rejected`]: (state, action) => {
      state.error = action.payload;
    },
    [`${terminateInstanceConstant}/pending`]: (state, action: any) => {
      const index = state.rentedInstances.findIndex(
        (rental) => rental.id === action.meta.arg
      );
      state.rentedInstances[index].instance.status = InstanceStatus.stopping;
    },
    [`${terminateInstanceConstant}/fulfilled`]: (state, action: any) => {
      const rentedInstanceIndex = state.rentedInstances.findIndex(
        (i) => i.id === action.meta.arg
      );
      if (rentedInstanceIndex > -1) {
        state.rentedInstances[rentedInstanceIndex].end =
          new Date().toISOString();
      }
    },
    [`${terminateInstanceConstant}/rejected`]: () => {},
    [`${rentInstanceConstant}/pending`]: (state, action: any) => {
      const instanceIndex = state.marketplaceInstances.findIndex(
        (instance) => instance.id === action.meta.arg.instanceId
      );
      if (state.marketplaceInstances[instanceIndex]) {
        state.marketplaceInstances[instanceIndex].status =
          InstanceStatus.starting;
      }
    },
    [`${rentInstanceConstant}/fulfilled`]: (state, action: any) => {
      const instanceIndex = state.marketplaceInstances.findIndex(
        (instance) => instance.id === action.meta.arg.instanceId
      );
      const marketplaceInstance = state.marketplaceInstances[instanceIndex];
      if (marketplaceInstance) {
        state.rentedInstances.push(
          buildOptimisticInstanceRental(marketplaceInstance)
        );
        state.marketplaceInstances = state.marketplaceInstances.filter(
          (instance) => instance.id !== action.meta.arg.instanceId
        );
      }
    },
    [`${rentInstanceConstant}/rejected`]: (state, action: any) => {
      const instanceIndex = state.marketplaceInstances.findIndex(
        (instance) => instance.id === action.meta.arg.instanceId
      );
      const marketplaceInstance = state.marketplaceInstances[instanceIndex];
      if (marketplaceInstance) {
        marketplaceInstance.status = InstanceStatus.node_ready;
      }
    },
  },
});

// marketplace instances
export const fetchMarketplaceInstances = createAsyncThunk(
  `instances/${fetchMarketplaceInstancesConstant}`,
  fetchMarketplace
);

const getMarketplaceInstances = (state: RootState) =>
  state.instances.marketplaceInstances;

export const getFormattedMarketplaceInstances = createSelector(
  [getMarketplaceInstances],
  (instances) => instances.map(formatInstance)
);

const getMarketplaceInstance = (state: RootState, instanceId?: string) =>
  state.instances.marketplaceInstances.find(
    (instance) => instance.id === instanceId
  );

export const getFormattedMarketplaceInstance = createSelector(
  [getMarketplaceInstance],
  (instance) => (instance ? formatInstance(instance) : instance)
);

export const getMarketplaceStatus = (state: RootState) =>
  state.instances.marketplaceStatus;

export const getMarketplaceInitialLoading = (state: RootState) =>
  state.instances.marketplaceStatus === ResponseStatus.Loading &&
  !state.instances.marketplaceInitialFetch;

// rented instances
export const fetchRentedInstances = createAsyncThunk(
  `instances/${fetchRentedInstancesConstant}`,
  fetchRented
);

const getRentedInstances = (state: RootState) =>
  state.instances.rentedInstances;

const getFilteredRentedInstances = createSelector(
  [getRentedInstances],
  (rInstances) => rInstances.filter((rental) => !rental.end)
);

export const getFormattedRentedInstances = createSelector(
  [getFilteredRentedInstances],
  (rentals) =>
    rentals.map((rental) => ({
      ...rental,
      instance: formatInstance(rental.instance),
    }))
);

export const getRentedInstancesInitialLoading = (state: RootState) =>
  state.instances.rentedInstancesStatus === ResponseStatus.Loading &&
  !state.instances.rentedInstancesInitialFetch;

export const getRentedInstance = (state: RootState, rentalId?: string) =>
  state.instances.rentedInstances.find((rental) => rental.id === rentalId);

export const getFormattedRentedInstance = createSelector(
  [getRentedInstance],
  (rental) =>
    rental
      ? {
          ...rental,
          instance: formatInstance(rental.instance),
        }
      : rental
);

export const rentInstance = createAsyncThunk(
  `instances/${rentInstanceConstant}`,
  rentInstanceDb
);

// supplied instances
export const fetchSuppliedInstances = createAsyncThunk(
  `instances/${fetchSuppliedInstancesConstant}`,
  fetchSupplied
);

const getSuppliedInstances = (state: RootState) =>
  state.instances.suppliedInstances;

export const getFormattedSuppliedInstances = createSelector(
  [getSuppliedInstances],
  (instances) => instances.map(formatInstance)
);

export const getSupplierInstructions = (state: RootState) =>
  state.instances.supplierInstructions;

export const fetchMarketplaceSupplierInstructions = createAsyncThunk(
  `instances/${fetchSupplierInstructionsConstant}`,
  fetchSupplierInstructions
);

export const getStatus = (state: RootState) => state.instances.status;

export const terminateInstance = createAsyncThunk(
  `instances/${terminateInstanceConstant}`,
  terminateInstanceDb
);

export default instancesSlice.reducer;
