import { PiHouseFill } from 'react-icons/pi';
import { GrCubes } from 'react-icons/gr';
import NavLinkItem from './NavLinkItem';
import { FiCpu } from 'react-icons/fi';
import BillingIcon from './common/icons/BillingIcon';
import SettingsIcon from './common/icons/SettingsIcon';
import TeamIcon from './common/icons/TeamIcon';
import HyperLink from './common/HyperLink';
import { useState } from 'react';
import twClassnames from '../utils/classnames';
import ChevronLeftDoubleIcon from './common/icons/ChevronLeftDoubleIcon';
import { useFeatureFlagEnabled, useFeatureFlagPayload } from 'posthog-js/react';
import { useSelector } from 'react-redux';
import { getIsAuthenticated } from '../slices/auth';
import HyperbolicLogoFull from './common/icons/HyperbolicLogoFull';
import ServerIcon from './common/icons/ServerIcon';
import MessageQuestionIcon from './common/icons/MessageQuestionIcon';
import Badge from './common/Badge';
import useLocalStorage from '../hooks/useLocalStorage';

const SideNavBar = () => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const billingEnabled = useFeatureFlagEnabled('billing');
  const teamEnabled = useFeatureFlagEnabled('team');
  const isAuthenticated = useSelector(getIsAuthenticated);
  const [rentLastSeen] = useLocalStorage('rent-last-seen');
  const rentLastUpdated = useFeatureFlagPayload('rent-last-updated');
  const rentLastUpdatedDate = rentLastUpdated
    ? new Date(rentLastUpdated as string)
    : false;
  const rentHasUpdated =
    rentLastUpdatedDate && new Date(rentLastSeen) <= rentLastUpdatedDate;
  return (
    <aside
      className={twClassnames(
        'hidden bg-theme-primary-50 md:flex flex-col px-3 sticky left-0 z-10 w-[210px] transition-all',
        { 'w-[70px]': isCollapsed }
      )}
    >
      <div
        className={twClassnames(
          'flex items-center mb-6 mt-7 h-[26px] relative transition-all',
          { 'justify-center': isCollapsed }
        )}
      >
        {!isCollapsed && (
          <HyperLink
            to="/"
            className="ml-2 flex items-center hover:no-underline"
          >
            <HyperbolicLogoFull width={130} />
          </HyperLink>
        )}
        <ChevronLeftDoubleIcon
          className={twClassnames(
            'transition text-theme-neutral-400 hover:text-theme-primary-600 cursor-pointer absolute right-0',
            { 'rotate-180 static': isCollapsed }
          )}
          onClick={() => setIsCollapsed(!isCollapsed)}
        />
      </div>
      <div
        className={twClassnames('flex mb-4 transition-all', {
          'ml-2': !isCollapsed,
          '-mx-1.5': isCollapsed,
        })}
      >
        <Badge text="alpha" className="text-xs" />
      </div>

      <nav className="flex flex-col">
        <NavLinkItem
          to="/"
          logo={<PiHouseFill size={20} />}
          title="Home"
          condensed={isCollapsed}
        />
        <NavLinkItem
          to="/models"
          logo={<GrCubes size={20} />}
          title="Models"
          condensed={isCollapsed}
        />
        <NavLinkItem
          to="/compute"
          logo={<FiCpu size={20} />}
          title="Rent GPUs"
          condensed={isCollapsed}
          hasUpdates={rentHasUpdated}
        />
        <NavLinkItem
          to="/supply"
          logo={<ServerIcon size={20} />}
          title="Supply GPUs"
          condensed={isCollapsed}
        />
        {billingEnabled && isAuthenticated && (
          <NavLinkItem
            to="/billing"
            logo={<BillingIcon size={20} />}
            title="Billing"
            condensed={isCollapsed}
          />
        )}
        {isAuthenticated && (
          <NavLinkItem
            to="/settings"
            logo={<SettingsIcon size={20} />}
            title="Settings"
            condensed={isCollapsed}
          />
        )}
        {teamEnabled && isAuthenticated && (
          <NavLinkItem
            to="/team"
            logo={<TeamIcon size={20} />}
            title="Team"
            condensed={isCollapsed}
          />
        )}
        <NavLinkItem
          to="/support"
          logo={<MessageQuestionIcon size={20} />}
          title="Support"
          condensed={isCollapsed}
        />
      </nav>
    </aside>
  );
};

export default SideNavBar;
