import { formatCurrency } from '../../../utils/value';
import Button from '../Button';
import { FaCircleNotch } from 'react-icons/fa6';
import { LuCreditCard } from 'react-icons/lu';
import { useDispatch, useSelector } from 'react-redux';
import {
  fetchGpuHourlySpend,
  getBalance,
  getGpuHourlySpend,
} from '../../../slices/usage';
import { FormEvent, useEffect, useState } from 'react';
import { fetchStripeCheckoutSessionURL } from '../../../services/stripe';
import { AppDispatch } from '../../../store';
import AddFundsInputGroup from './AddFundsInputGroup';
import { displayRuntime, getMinutesRemaining } from '../../../utils/billing';
import twClassnames from '../../../utils/classnames';
import { usePostHog } from 'posthog-js/react';

const MAX_AMOUNT = 10000;
const OPTIONS = [25, 50, 100];
const RUNTIME_MINUTES_WARNING_THRESHOLD = 15;

const AddFunds = ({
  containerClassName,
  showBalancePreview = true,
  preselectFirstOption = true,
  showLowBalanceWarnings = false,
}: {
  containerClassName?: string;
  showBalancePreview?: boolean;
  preselectFirstOption?: boolean;
  showLowBalanceWarnings?: boolean;
}) => {
  const posthog = usePostHog();
  const dispatch = useDispatch<AppDispatch>();
  const balance = useSelector(getBalance);
  const gpuSpend = useSelector(getGpuHourlySpend);
  const [amount, setAmount] = useState<number | null>(
    preselectFirstOption ? OPTIONS[0] : null
  );
  const [loading, setLoading] = useState(false);
  const [redirecting, setRedirecting] = useState(false);
  const [valid, setValid] = useState(true);
  const [formError, setFormError] = useState('');
  const [networkError, setNetworkError] = useState('');

  const buttonDisabled = !amount || !valid || loading || redirecting;
  const errorMessage = formError || networkError;

  const currentBalanceLow = balance && balance < 0;
  const previewBalanceLow = balance && amount && balance + amount * 100 < 0;

  const currentRuntimeLow =
    getMinutesRemaining(gpuSpend, balance || 0) <
    RUNTIME_MINUTES_WARNING_THRESHOLD;
  const previewRuntimeLow =
    getMinutesRemaining(gpuSpend, (balance || 0) + (amount || 0) * 100) <
    RUNTIME_MINUTES_WARNING_THRESHOLD;

  useEffect(() => {
    dispatch(fetchGpuHourlySpend());
  }, [dispatch]);

  // todo: do we want this network call handled by redux?
  const fetchCheckoutURL = async (amount: number) => {
    setLoading(true);
    let url;
    try {
      url = await fetchStripeCheckoutSessionURL(amount);
    } catch (error) {
      console.error('error fetching checkout url', error);
      return { url: null, error };
    }
    setLoading(false);
    return { url, error: null };
  };

  const handlePayNow = async (e: FormEvent) => {
    e.preventDefault();
    if (!amount) {
      return;
    }
    posthog?.capture('Pay Now Clicked', { amount });
    const { error, url } = await fetchCheckoutURL(amount);
    if (url) {
      setRedirecting(true);
      window.location = url;
    }
    if (error) {
      setNetworkError('Something went wrong, please try again later');
    }
  };

  const handleInputChanged = ({ value }: { value: number | null }) => {
    if (!value) {
      setValid(true);
    } else if (value > MAX_AMOUNT) {
      setValid(false);
      setFormError('You have entered an amount that is too high');
    } else {
      setValid(true);
      setFormError('');
      setNetworkError('');
    }

    setAmount(value);
  };

  return (
    <div className={twClassnames('flex flex-col gap-4', containerClassName)}>
      <div className="text-lg text-black font-semibold">
        <span>Balance:</span>
        <span
          className={twClassnames(
            'ml-2 text-theme-primary-600',
            {
              'line-through decoration-2 text-theme-neutral-600':
                showBalancePreview && amount && !formError,
            },
            { 'text-theme-danger-600': currentBalanceLow }
          )}
        >
          {balance != null ? formatCurrency(balance / 100) : '--'}
        </span>
        {showBalancePreview && !formError && !!balance && amount && (
          <span
            className={twClassnames(
              'ml-2',
              previewBalanceLow
                ? 'text-theme-danger-600'
                : 'text-theme-primary-600'
            )}
          >
            {formatCurrency(balance / 100 + amount)}
          </span>
        )}
        {currentBalanceLow && (
          <div className="text-theme-danger-600 text-sm font-thin mt-1">
            Low balance. Please add funds to avoid service interruption.
          </div>
        )}
      </div>

      <div className="text-sm text-black font-semibold">Add credits</div>
      <div className="flex flex-col sm:flex-row gap-2">
        <AddFundsInputGroup
          amount={amount || 0}
          disabled={loading || redirecting}
          onInputChanged={handleInputChanged}
          options={OPTIONS}
        />
        <Button
          disabled={buttonDisabled}
          onClick={handlePayNow}
          className="flex self-center items-center gap-2 whitespace-nowrap"
        >
          <LuCreditCard size={20} />
          <span>{loading || redirecting ? `One sec` : `Pay Now`}</span>
          {(loading || redirecting) && (
            <FaCircleNotch className="animate-spin" />
          )}
        </Button>
      </div>
      {errorMessage && (
        <div className="text-theme-danger-500 mt-4">{errorMessage}</div>
      )}
      <div className="w-full h-px bg-theme-neutral-200"></div>
      <div>
        <div className="text-black text-md font-semibold">
          Current GPU Spend: {formatCurrency(gpuSpend / 100)} / hr
        </div>
        {!!balance && (
          <>
            <div className="text-sm font-thin mt-1">
              Estimated run time with current spend:&nbsp;
              <span className="text-black">
                {gpuSpend ? (
                  <>
                    <span
                      className={twClassnames({
                        'font-semibold line-through decoration-1 text-theme-neutral-600':
                          amount && !formError,
                        'text-theme-danger-600 font-semibold':
                          currentRuntimeLow,
                      })}
                    >
                      {displayRuntime(gpuSpend, balance)}
                    </span>
                    {amount && !formError && (
                      <span
                        className={twClassnames(
                          'ml-2 font-semibold text-theme-primary-600',
                          {
                            'text-theme-danger-600 font-semibold':
                              previewRuntimeLow,
                          }
                        )}
                      >
                        {displayRuntime(gpuSpend, balance + amount * 100)}
                      </span>
                    )}
                  </>
                ) : (
                  'N/A'
                )}
              </span>
            </div>
            {showLowBalanceWarnings && currentRuntimeLow && (
              <div className="text-theme-danger-600 text-sm font-thin mt-1">
                Services may be terminated soon due to insufficient funds.
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default AddFunds;
