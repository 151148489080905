import {
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { ResponseStatus, Machine } from '../utils/types';
import {
  createMachineDb,
  createMachineRequestDb,
  deleteMachineDb,
  deleteMachineRequestDb,
  fetchMachineRequestsDb,
  fetchMachinesDb,
  fetchSingleMachineDb,
} from '../services/machines';
import { RootState } from '../store';
import orderBy from 'lodash/orderBy';

interface MachineSlice {
  machines: Machine[];
  machineRequests: any[];
  status: ResponseStatus;
  error: string;
}

const initialState: MachineSlice = {
  machines: [],
  machineRequests: [],
  status: ResponseStatus.Unfetched,
  error: '',
};

const fetchMachinesConstant = 'fetchMachines';
const fetchSingleMachineConstant = 'fetchSingleMachineConstant';
const fetchMachineRequestsConstant = 'fetchMachineRequestsConstant';
const deleteMachineRequestConstant = 'deleteMachineRequest';
const createMachineConstant = 'createMachine';
const createMachineRequestConstant = 'createMachineRequest';
const deleteMachineConstant = 'deleteMachine';

const addMachineReducer = (state: MachineSlice, action: any) => {
  const machineIndex = state.machines.findIndex(
    (mac: Machine) => mac.id === action.payload.id
  );
  if (machineIndex > -1) {
    state.machines[machineIndex] = action.payload;
  } else {
    state.machines = state.machines.concat(action.payload);
  }
};

const addMachineRequestReducer = (state: MachineSlice, action: any) => {
  const machineIndex = state.machineRequests.findIndex(
    (mac: Machine) => mac.id === action.payload.id
  );
  if (machineIndex > -1) {
    state.machineRequests[machineIndex] = action.payload;
  } else {
    state.machineRequests = state.machineRequests.concat(action.payload);
  }
};

const machinesSlice = createSlice({
  name: 'machines',
  initialState,
  reducers: {
    [`${fetchMachinesConstant}/pending`]: (state) => {
      state.status = ResponseStatus.Loading;
    },
    [`${fetchMachinesConstant}/fulfilled`]: (state, action) => {
      state.status = ResponseStatus.Success;
      state.machines = action.payload;
    },
    [`${fetchMachinesConstant}/rejected`]: (state, action) => {
      state.status = ResponseStatus.Failure;
      state.error = action.payload;
    },
    [`${fetchSingleMachineConstant}/pending`]: () => {},
    [`${fetchSingleMachineConstant}/fulfilled`]: addMachineReducer,
    [`${fetchSingleMachineConstant}/rejected`]: (state, action) => {
      state.error = action.payload;
    },
    [`${createMachineConstant}/pending`]: () => {},
    [`${createMachineConstant}/fulfilled`]: addMachineReducer,
    [`${createMachineConstant}/rejected`]: (state, action) => {
      // state.status = ResponseStatus.Failure;
      state.error = action.payload;
    },
    [`${deleteMachineConstant}/pending`]: () => {},
    [`${deleteMachineConstant}/fulfilled`]: (state, action) => {
      const machineIndex = state.machines.findIndex(
        (mac) => mac.id === action.payload
      );
      if (machineIndex > -1) {
        state.machines = state.machines
          .slice(0, machineIndex)
          .concat(state.machines.slice(machineIndex + 1));
      }
    },
    [`${deleteMachineConstant}/rejected`]: (state, action) => {
      // state.status = ResponseStatus.Failure;
      state.error = action.payload;
    },
    [`${fetchMachineRequestsConstant}/pending`]: () => {},
    [`${fetchMachineRequestsConstant}/fulfilled`]: (state, action) => {
      state.machineRequests = action.payload;
    },
    [`${fetchMachineRequestsConstant}/rejected`]: () => {},
    [`${createMachineRequestConstant}/pending`]: () => {},
    [`${createMachineRequestConstant}/fulfilled`]: addMachineRequestReducer,
    [`${createMachineRequestConstant}/rejected`]: () => {},
    [`${deleteMachineRequestConstant}/pending`]: () => {},
    [`${deleteMachineRequestConstant}/fulfilled`]: (state, action) => {
      const machineIndex = state.machineRequests.findIndex(
        (mac) => mac.id === action.payload
      );
      if (machineIndex > -1) {
        state.machineRequests = state.machineRequests
          .slice(0, machineIndex)
          .concat(state.machineRequests.slice(machineIndex + 1));
      }
    },
    [`${deleteMachineRequestConstant}/rejected`]: (state, action) => {
      // state.status = ResponseStatus.Failure;
      state.error = action.payload;
    },
  },
});

export const fetchMachines = createAsyncThunk(
  `machines/${fetchMachinesConstant}`,
  fetchMachinesDb
);

export const fetchSingleMachine = createAsyncThunk(
  `machines/${fetchSingleMachineConstant}`,
  fetchSingleMachineDb
);

export const fetchMachineRequests = createAsyncThunk(
  `machines/${fetchMachineRequestsConstant}`,
  fetchMachineRequestsDb
);

export const deleteMachineRequest = createAsyncThunk(
  `machines/${deleteMachineRequestConstant}`,
  deleteMachineRequestDb
);

export const createMachine = createAsyncThunk(
  `machines/${createMachineConstant}`,
  createMachineDb
);

export const createMachineRequest = createAsyncThunk(
  `machines/${createMachineRequestConstant}`,
  createMachineRequestDb
);

export const deleteMachine = createAsyncThunk(
  `machines/${deleteMachineConstant}`,
  deleteMachineDb
);

export const getMachines = (state: RootState) => state.machines.machines;
export const getSortedMachines = createSelector([getMachines], (machines) =>
  orderBy(machines, 'created', 'desc')
);

export const getMachine = (state: RootState, machineId?: string) =>
  state.machines.machines.find((machine) => machine.id === machineId);
export const getMachineRequests = (state: RootState) =>
  state.machines.machineRequests;

export default machinesSlice.reducer;
